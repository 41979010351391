import master from './master';

const userData = JSON.parse(localStorage.getItem('userData'));
const renewalStatus = userData?.branch?.renewal_status;
const pettyCash = userData?.can_open_petty_cash;

const menus = [
  {
    title: 'Laporan',
    route: 'report.index',
    permission: [
      'dashboard',
      'laporan pembayaran ke pemasok',
      'laporan pembayaran lainnya',
      'laporan pembayaran piutang',
      'laporan utang',
      'laporan piutang',
      'laporan pembelian stok gudang',
      'laporan pembelian stok pemasok',
      'laporan kasir',
      'laporan stok aktual',
      'laporan penjualan',
      'laporan laba kotor',
      'kartu stok',
    ],
    icon: 'PieChartIcon',
    // icon: require('@/assets/images/icons/DocumentBlank.svg'),
    // iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
    disabled: renewalStatus === 'free',
  },
  // {
  //   title: "Transaksi",
  //   route: "cashier.index",
  //   permission: "kasir",
  //   icon: "DollarSignIcon",
  //   // icon: require('@/assets/images/icons/Kasir.svg'),
  //   // iconActive: require('@/assets/images/icons/kasir-active.svg'),
  //   id: "tour-1",
  // },
  {
    title: 'Kas Kecil',
    route: 'cash.index',
    permission: 'kas kecil',
    icon: 'CreditCardIcon',
    // disabled: pettyCash === false,
  },
  {
    title: 'Riwayat Transaksi',
    route: 'transaction.index',
    // permission: 'buat transaksi',
    icon: 'ArchiveIcon',
    disabled: renewalStatus === 'free',
  },
  // {
  //   title: "Keranjang",
  //   route: "cart.index",
  //   permission: ["list semua keranjang", "list semua keranjang ecommerce"],
  //   icon: "ShoppingCartIcon",
  //   // icon: require('@/assets/images/icons/Cart.svg'),
  //   // iconActive: require('@/assets/images/icons/cart-active.svg'),
  //   disabled: renewalStatus === "free",
  // },
  // {
  //   title: 'Pembelian',
  //   route: 'purchase.index',
  //   icon: 'ShoppingBagIcon',
  // },
  {
    title: 'Stok',
    route: 'stock.index',
    permission: [
      'list stok gudang',
      'list aktual stok gudang',
      'list stok supplier',
      'list aktual stok supplier',
      'transfer stok',
      'list stock opname',
    ],
    icon: 'BoxIcon',
    disabled: renewalStatus === 'free',
  },
  {
    title: 'Products',
    permission: 'list produk',
    icon: 'CodesandboxIcon',
    children: [
      {
        title: 'SKU Master',
        route: 'master-product.index',
        icon: 'CodepenIcon',
      },
      {
        title: 'Kategori',
        // route: "barang.index",
        // icon: 'CustomCategory',
        icon: 'CustomCategory',
        children: [
          {
            title: 'Kategori 1',
            route: 'category1',
          },
          {
            title: 'Kategori 2',
            route: 'category2',
          },
          {
            title: 'Kategori 3',
            route: 'category3',
          },
        ],
      },
      // {
      //   title: "Variations",
      //   route: "variation.index",
      //   icon: "DatabaseIcon",
      //   permission: "list variation",
      // },
      {
        title: 'Brand',
        route: 'brand.index',
        icon: 'FileTextIcon',
      },
      {
        title: 'Unit',
        route: 'satuan.index',
        icon: 'GitBranchIcon',
      },
      {
        title: 'Bundle',
        route: 'bundle.index',
        icon: 'ShoppingBagIcon',
        permission: 'list product bundling',
      },
    ],
  },
  // {
  //   title: 'SKU Master',
  //   route: 'master-product.index',
  //   icon: 'FolderIcon',
  // },
  // {
  //   title: 'Categories',
  //   route: 'barang.index',
  //   icon: 'FolderIcon',
  // },
  // {
  //   title: 'Variations',
  //   route: 'variation.index',
  //   permission: 'list variation',
  //   icon: 'FolderIcon',
  // },
  // {
  //   title: 'Brand',
  //   route: 'brand.index',
  //   icon: 'FolderIcon',
  // },
  // {
  //   title: 'Unit',
  //   route: 'satuan.index',
  //   icon: 'FolderIcon',
  // },
  {
    title: 'Retur',
    route: 'retur.index',
    permission: 'list retur',
    icon: 'RotateCcwIcon',
    // icon: require('@/assets/images/icons/WalletorRetur.svg'),
    // iconActive: require('@/assets/images/icons/WalletorRetur-Solid.svg'),
    disabled: renewalStatus === 'free',
  },
  {
    title: 'Relasi',
    route: 'customer.index',
    permission: ['list pelanggan', 'list supplier'],
    icon: 'UsersIcon',

    // icon: require('@/assets/images/icons/User.svg'),
    // iconActive: require('@/assets/images/icons/User-Solid.svg'),
  },
  {
    title: 'Deposit',
    route: 'deposit.index',
    permission: 'list pelanggan',
    icon: 'InboxIcon',
    // icon: require('@/assets/images/icons/WalletorRetur.svg'),
    // iconActive: require('@/assets/images/icons/WalletorRetur-Solid.svg'),
    disabled: renewalStatus === 'free',
  },
  {
    title: 'Utang & Piutang',
    route: 'debt.index',
    permission: ['list utang', 'list piutang', 'list voucher'],
    icon: 'BarChart2Icon',
    // icon: require('@/assets/images/icons/DocumentBlank.svg'),
    // iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
    disabled: renewalStatus === 'free',
  },
  {
    title: 'Pembayaran',
    route: 'payout.index',
    permission: [
      'list pembayaran ke supplier',
      'list pembayaran lainnya',
      'list pembayaran penjualan',
    ],
    icon: 'DatabaseIcon',
    // icon: require('@/assets/images/icons/DocumentBlank.svg'),
    // iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
    disabled: renewalStatus === 'free',
  },
  {
    title: 'Ticket Support',
    route: 'ticket.index',
    icon: 'TabletIcon',
    tag: '0',
    tagVariant: 'light-danger',
    refName: 'notificationTicket',
    // icon: require('@/assets/images/icons/DocumentBlank.svg'),
    // iconActive: require('@/assets/images/icons/DocumentBlank-Solid.svg'),
  },
  // {
  //   title: "Supplier Dashboard",
  //   route: "login-supplier",
  //   // permission: 'list pembayaran ke supplier' && 'list pembayaran lainnya',
  //   icon: require("@/assets/images/icons/DocumentBlank.svg"),
  //   iconActive: require("@/assets/images/icons/DocumentBlank-Solid.svg"),
  //   disabled: renewalStatus === "free",
  // },
  ...master,
].filter((menu) => !menu.disabled);

export default menus;
