<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="align-items-center flex-grow-1 d-none d-lg-flex mr-50 border-right" style="gap: 1rem">
        <b-button
          v-if="checkPermission('kasir')"
          variant="primary"
          :to="{ name: 'pos.index'}"
        >
          <feather-icon
            icon="ApertureIcon"
            class="mr-50"
          />
          <span class="align-middle">POS</span>
        </b-button>
        <b-button variant="primary" class="d-flex align-items-center" @click="handleHardRefresh">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>
        <b-button
          @click="toggleFullScreen"
          variant="primary"
          class=""
        >
          <feather-icon
            :icon="!is_fullscreen ? 'MaximizeIcon' : 'MinimizeIcon'"
            class=""
          />
        </b-button>
        <b-button
          v-if="checkPermission(['list semua keranjang', 'list semua keranjang ecommerce'])"
          variant="primary"
          class="btn-icon"
          :to="{ name: 'cart.index'}"
        >
          <feather-icon icon="ShoppingCartIcon" />
        </b-button>
        <hr>
      </div>
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template v-if="userData !== undefined" #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-dark">
              {{ userData.name || '-' }}
            </p>
            <span class="user-status" style="text-transform: capitalize;color: #2B324F !important;">{{ userRole.join(' | ') }}</span>
          </div>
          <b-avatar size="40" :src="userData.photo_url" variant="light-primary" badge class="badge-minimal"
            badge-variant="success" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="goToProfile()">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <div>
          <!-- <feather-icon size="16" icon="UserIcon" class="mr-50" /> -->
          <!-- <span>Cabang</span> -->
          <div class="d-flex flex-column px-1" id="switch--branch" v-if="checkPermission('change cabang user')">
            <div class="">
              <b-form-select
                v-model="selected_branch"
                :options="options"
                @change="changeBranchUser"
              />
            </div>
          </div>
        </div>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormSelect, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import { checkPermission } from '@/auth/utils'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    BFormSelect,
    BButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  setup() {
    return {
      checkPermission,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userPermission: JSON.parse(localStorage.getItem('userPermission')),
      userRole: JSON.parse(localStorage.getItem('userRole')),
      selected_branch: "",
      options: [],
      is_fullscreen: false
    }
  },
  mounted() {
    this.getCabang()
    if (localStorage.getItem('accessToken')) {
      this.getProfiles()
    }
    this.jabatan = this.$store.state.profile.jabatan
  },
  methods: {
    handleHardRefresh() {
      window.location.reload(true)
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.is_fullscreen = true
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        this.is_fullscreen = false
        document.exitFullscreen();
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userPermission')
      localStorage.removeItem('userRole')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    async getProfiles() {
      await this.$store.dispatch('profile/getProfile')
        .then(response => {
          const userData = JSON.stringify(response.data.data)
          localStorage.setItem('userData', userData)
          this.userData = response.data.data
          this.selected_branch = response.data.data.branch.uuid
          this.$store.commit('profile/SET_JABATAN', response.data.data.jabatan)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    goToProfile() {
      // Redirect to login page
      this.$router.push({ name: 'welcome.index' })
    },
    async getCabang() {
      this.$store.dispatch('cabang/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        this.options = []
        const datas = result.data.data.data
        for (const data of datas) {
          this.options.push({
            value: data.uuid,
            text: data.name
          })
        }
        // this.options = result.data.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    changeBranchUser() {
      this.$store.dispatch('cabang/changeBranch', {
        user_id: this.userData.id,
        payload: {
          _method: 'PATCH',
          branch_uuid: this.selected_branch
        },
      }).then(response => {
        this.getProfiles()
        this.$router.go(0)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>
